/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import CampusLifeInfo from "component/CampusLifeContent/CampusLifeInfo";
import useScrollToTop from "hooks/useScrollToTop";

export default function CampusLifePage() {
  useScrollToTop();

  return (
    <Box marginTop={[9, 9, 9, 2]}>
      <CampusLifeInfo />
    </Box>
  );
}
