import FloatingButton from "component/FloatingButton";
import FeatureSection from "component/HomeContent/FeatureSection";
import NewsSection from "component/HomeContent/NewsSection";
import OpenCampusSection from "component/HomeContent/OpenCampusSection";
import TopDesktopVideoSection from "component/HomeContent/TopDesktopVideoSection";
import TopMobileVideoSection from "component/HomeContent/TopMobileVideoSection";
import VideoSection from "component/HomeContent/VideoSection";
import { useBreakpoint } from "hooks/useBreakpoint";
import useScrollToTop from "hooks/useScrollToTop";

function HomePage() {
  useScrollToTop();
  const { isDesktop } = useBreakpoint();

  return (
    <>
      <FloatingButton />
      {isDesktop ? <TopDesktopVideoSection /> : <TopMobileVideoSection />}
      <VideoSection />
      <FeatureSection />
      <OpenCampusSection />
      <NewsSection />
    </>
  );
}
export default HomePage;
