const formatDate = (date: string) => {
  const rawDate = new Date(date);
  return rawDate
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, ".");
};

const constructYoutubeUrl = (id: string) =>
  `https://www.youtube.com/embed/${id}`;

export { formatDate, constructYoutubeUrl };
