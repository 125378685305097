import { SIZE_FACTOR_MAPPING } from "Theme";

const regex = /^([0-9]+(?:\.[0-9]+)?)\s*(\S+)$/;
const getResponsiveSize = (size: string | number, breakpoint: number) => {
  if (typeof size === "number") return size * SIZE_FACTOR_MAPPING[breakpoint];

  const match = size.match(regex) as RegExpMatchArray;
  const number = parseFloat(match[1]);
  const unit = match[2];

  return `${number * SIZE_FACTOR_MAPPING[breakpoint]}${unit}`;
};

export { getResponsiveSize };
