/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import YoutubeBlock from "component/YoutubeBlockNew";
import YoutubeShortBlock from "component/YoutubeShortBlock";
import HorizontalScrollContainer from "component/HorizontalScrollContainer";
import { getResponsiveSize } from "utils/responsiveUtils";
import { useBreakpoint } from "hooks/useBreakpoint";
import SectionTitle from "component/SectionTitle";
import { CharacteristicData, CharacteristicGraphqlData } from "type/type";

function FeatureVideoSection({
  characteristicData,
  postData,
}: {
  characteristicData: CharacteristicData;
  postData: CharacteristicGraphqlData;
}) {
  const { breakpoint } = useBreakpoint();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <Box id="feature-video">
      <SectionTitle
        title="幼児教育科の魅力"
        textAlign="center"
        subTitle={characteristicData.subTitle}
      />
      <Box marginBottom={4}>
        <HorizontalScrollContainer
          buttonProps={{ top: "45%" }}
          columnGap={getResponsiveSize("2rem", breakpoint)}
        >
          {characteristicData.short.map((data, idx) => (
            <YoutubeShortBlock url={data.url} title={data.title} key={idx} />
          ))}
        </HorizontalScrollContainer>
      </Box>
      <Box>
        <HorizontalScrollContainer buttonProps={{ top: "35%" }}>
          {characteristicData.youtube.map((data, idx) => (
            <YoutubeBlock url={data.url} title={data.title} key={idx} />
          ))}
        </HorizontalScrollContainer>
      </Box>
      {postData.content && (
        <Box dangerouslySetInnerHTML={{ __html: postData.content }} />
      )}
    </Box>
  );
}

export default FeatureVideoSection;
