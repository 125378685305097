/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import FeatureVideoSection from "component/CharacteristicContent/FeatureVideoSection";
import { Navigate, useLoaderData } from "react-router";
import { CharacteristicPageLoaders } from "type/type";
import useScrollToTop from "hooks/useScrollToTop";

export default function CharacteristicPage() {
  const { videoData, postData } = useLoaderData() as Awaited<
    ReturnType<CharacteristicPageLoaders>
  >;
  useScrollToTop();

  if (videoData === null) {
    return <Navigate replace to="/" />;
  }

  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   const hash = window.location.hash.substring(1);
  //   if (hash.length > 0) {
  //     const targetDiv = document.getElementById(hash);
  //     targetDiv?.scrollIntoView(true);
  //   }
  // }, [isLoading]);

  return (
    <Box marginTop={[9, 9, 9, 2]}>
      <FeatureVideoSection characteristicData={videoData} postData={postData} />
    </Box>
  );
}
