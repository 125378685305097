/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import ContentWrapper from "component/ContentWrapper";
import UniversityIcon from "component/Icons/UniversityIcon";
import { ReactComponent as InfoIcon } from "assets/linkIcons/info.svg";
import { ReactComponent as AccessIcon } from "assets/linkIcons/access.svg";
import { ReactComponent as OpenCampusArrow } from "assets/icons/open-campus-arrow.svg";
import { navigationList } from "component/Navigation/navigationList";
import { getResponsiveSize } from "utils/responsiveUtils";
import { useBreakpoint } from "hooks/useBreakpoint";

const FooterButton = ({
  Icon,
  title,
  href,
  external = true,
}: {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  href: string;
  external?: boolean;
}) => {
  const { breakpoint } = useBreakpoint();

  return (
    <Button
      sx={{
        bgcolor: "#EDA14B",
        color: "#FFF",
        fontSize: [
          `${getResponsiveSize("1rem", breakpoint)}`,
          null,
          `${getResponsiveSize("0.825rem", breakpoint)}`,
        ],
        "&: hover, &:active": {
          bgcolor: "#ba8a54",
        },
        marginX: "auto",
        marginY: "0.5rem",
        width: ["100%", "100%", "45%"],
        maxWidth: "400px",
      }}
      startIcon={<Icon />}
      variant="contained"
      target={external ? "_blank" : "_self"}
      rel="noreferrer"
      href={href}
    >
      {title}
    </Button>
  );
};

function FooterLink({
  title,
  url,
  internal,
}: {
  title: string;
  url: string;
  internal?: boolean;
}) {
  const { breakpoint } = useBreakpoint();
  return (
    <Box
      sx={{
        flex: "1 0 100%",
        flexBasis: ["50%", "50%", "30%", "50%"],
        whiteSpace: "nowrap",
      }}
      pb={[0, 0, 0, 1]}
      pt={[1, 1, 1, 1]}
      px={[0, 0, 0, 1]}
    >
      <Link
        href={url}
        underline="none"
        color="#000"
        target={internal ? undefined : "_blank"}
        rel="noreferrer"
        fontSize={`${getResponsiveSize("1rem", breakpoint)}`}
      >
        <OpenCampusArrow
          css={css`
            vertical-align: middle;
          `}
          title={title}
        />
        {title}
      </Link>
    </Box>
  );
}
const EXCLUDE_FOOTER_LINK = new Set<string>(["交通アクセス", "資料請求"]);

function Footer() {
  const { isDesktop } = useBreakpoint();
  return (
    <Box id="footer">
      <Box bgcolor="#FFD7D7" py={[2, 4, 6]}>
        <ContentWrapper
          sx={{
            width: ["90%", "80%", "80%"],
          }}
        >
          <Grid
            container
            spacing={2}
            flexDirection={[
              "column-reverse",
              "column-reverse",
              "column-reverse",
              "row",
            ]}
          >
            <Grid
              item
              xs={12}
              lg={6}
              flexDirection={[
                "column-reverse",
                "column-reverse",
                "column-reverse",
                "column",
              ]}
              display="flex"
            >
              <Box display="flex" flexDirection="column" mb={[1, 1, 2]}>
                <UniversityIcon
                  margin={!isDesktop ? "auto" : undefined}
                  maxWidth="300px"
                  minHeight="60px"
                />
                <Typography variant="S" textAlign="center">
                  〒114-0033　東京都北区十条台1-7-13
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={["column", "column", "column", "row"]}
              >
                <FooterButton
                  Icon={InfoIcon}
                  title="資料請求・デジタルパンフ"
                  href={
                    navigationList.find(
                      (linkData) => linkData.title === "資料請求"
                    )?.url as string
                  }
                />
                <FooterButton
                  Icon={AccessIcon}
                  title="交通アクセス"
                  external={false}
                  href={
                    navigationList.find(
                      (linkData) => linkData.title === "交通アクセス"
                    )?.url as string
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1}>
                {navigationList.flatMap((linkInfo) => {
                  if (EXCLUDE_FOOTER_LINK.has(linkInfo.title)) {
                    return [];
                  }

                  return (
                    <FooterLink
                      key={linkInfo.title}
                      title={linkInfo.title}
                      url={linkInfo.url}
                      internal={linkInfo.internal}
                    />
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </ContentWrapper>
      </Box>
      <Box py={2} textAlign="center">
        <Typography variant="XS">
          COPYRIGHT © 2023 TOKYO SEITOKU UNIVERSITY. ALL RIGHTS RESERVED.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
