import { Box, Typography } from "@mui/material";
import { TeacherListData } from "type/type";

export default function TeacherCell({
  slug,
  name,
  profileSrc,
  title,
  description,
  isReady,
}: TeacherListData) {
  return (
    <Box
      width={120}
      m={2}
      component="a"
      href={isReady ? `/teacher/${slug}` : undefined}
      sx={{
        textDecoration: "none",
      }}
      flexGrow={[1, 1, 0, 0]}
    >
      <Box
        height={160}
        width={120}
        display="flex"
        justifyContent="center"
        sx={{
          clipPath: "ellipse(45% 50% at 50% 50%)",
        }}
      >
        <img src={profileSrc} alt={name} height="100%" />
      </Box>
      <Box py={2}>
        <Typography component="p" variant="M" color="#49555B" fontWeight="bold">
          {title || <br />}
        </Typography>
        <Typography component="p" variant="M" color="#49555B" fontWeight="bold">
          {name}
        </Typography>

        {description.split(/\r?\n/).map((splitString, idx) => (
          <Typography component="p" variant="XS" color="#49555B" key={idx}>
            {splitString}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
