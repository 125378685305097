import { Theme, createTheme } from "@mui/material";

const fontFamily = [
  "Noto Sans JP",
  "ヒラギノ角ゴ Pro W3",
  "Hiragino Kaku Gothic Pro",
  "Osaka",
  "メイリオ",
  "Meiryo",
  "ＭＳ Ｐゴシック",
  "MS PGothic",
  "ＭＳ ゴシック",
  "MS Gothic",
  "Noto Sans CJK JP",
  "TakaoPGothic",
  "sans-serif",
].join(",");

type TYPOGRAPHY_VARIANTS_TYPE =
  keyof typeof TYPOGRAPHY_VARIANTS_FONTSIZE_MAPPING;

type TypographyVariantsType = {
  [key in TYPOGRAPHY_VARIANTS_TYPE]?: React.CSSProperties;
};

// allow configuration using `createTheme`
type TypographyVariantsOptionsType = {
  [key in TYPOGRAPHY_VARIANTS_TYPE]?: React.CSSProperties;
};

type TypographyPropsVariantOverridesType = {
  [key in TYPOGRAPHY_VARIANTS_TYPE]?: true;
};

declare module "@mui/material/styles" {
  interface TypographyVariants extends TypographyVariantsType {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends TypographyVariantsOptionsType {}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides
    extends TypographyPropsVariantOverridesType {}
}

const TYPOGRAPHY_VARIANTS_FONTSIZE_MAPPING = {
  // 3.75rem
  Title: 60,
  // 2.75rem
  XXXXL: 44,
  // 2.5rem
  XXXL: 40,
  // 2.25rem
  XXL: 36,
  // 2rem
  XL: 32,
  // 1.75rem
  L: 28,
  // 1.5rem
  M: 24,
  // 1.25rem
  S: 20,
  // 1rem
  XS: 16,
  // 0.75rem,
  XXS: 12,
} as const;

const defaultFontSize = 16;

const SIZE_FACTOR_MAPPING = [0.65, 0.8, 0.85, 0.9, 1.1];

const DEFAULT_BREAKPOINT = 3;

const convertPxToRem = (px: number, breakpoint = DEFAULT_BREAKPOINT) =>
  `${(px / defaultFontSize) * SIZE_FACTOR_MAPPING[breakpoint]}rem`;

let theme = createTheme({
  typography: {
    htmlFontSize: defaultFontSize,
    fontFamily: fontFamily,
  },
});

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          maxWidth: "100%",
          overflowX: "hidden",
        },
        body: {
          maxWidth: "100%",
          overflowX: "hidden",
          [theme.breakpoints.up("lg")]: {
            overflowX: "visible",
          },
        },
      },
    },
  },
});

const responsiveFontSizeTypography = (theme: Theme) => {
  for (const key in TYPOGRAPHY_VARIANTS_FONTSIZE_MAPPING as Record<
    TYPOGRAPHY_VARIANTS_TYPE,
    number
  >) {
    const variants = key as TYPOGRAPHY_VARIANTS_TYPE;
    const fontSize = TYPOGRAPHY_VARIANTS_FONTSIZE_MAPPING[variants];

    theme.typography[variants] = {
      [theme.breakpoints.up("sm")]: {
        fontSize: convertPxToRem(fontSize, 1),
      },
      [theme.breakpoints.up("md")]: {
        fontSize: convertPxToRem(fontSize, 2),
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: convertPxToRem(fontSize, 3),
      },
      fontSize: convertPxToRem(fontSize, 0),
    };
  }
};
responsiveFontSizeTypography(theme);

export { theme, SIZE_FACTOR_MAPPING };
