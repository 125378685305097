import { CharacteristicGraphqlData } from "type/type";
import { gql } from "@apollo/client";
import apolloClient from "graphql/client";

const GET_CHARACTERISTIC_BY_SLUG = gql`
  query getCharacteristicBySlug($slug: String!) {
    characteristicBy(slug: $slug) {
      content(format: RENDERED)
      title(format: RENDERED)
    }
  }
`;

const getCharacteristicBySlugLoader = async (
  id: string
): Promise<CharacteristicGraphqlData | null> => {
  const result: any = await apolloClient.query({
    query: GET_CHARACTERISTIC_BY_SLUG,
    variables: {
      slug: id,
    },
    fetchPolicy: "cache-first",
  });

  const payload = result.data?.characteristicBy;

  if (!payload) {
    return null;
  }

  return {
    content: payload.content,
  };
};

export default getCharacteristicBySlugLoader;
