import { TeacherListData } from "type/type";
import { gql } from "@apollo/client";
import apolloClient from "graphql/client";

const GET_TEACHER_LIST = gql`
  query {
    teachers(first: 200) {
      edges {
        node {
          id
          teacherFieldGroup {
            name
            title
            ready
            description
            profilePicture {
              id
              uri
              sourceUrl
            }
          }
          slug
        }
      }
    }
  }
`;

const getTeacherBySlugLoader = async (): Promise<TeacherListData[] | null> => {
  const result = await apolloClient.query<{
    teachers: {
      edges: {
        node: {
          id: string;
          slug: string;
          teacherFieldGroup: {
            description: string;
            name: string;
            profilePicture: {
              id: string;
              sourceUrl: string;
              uri: string;
            };
            ready: boolean;
            title: string | null;
          };
        };
      }[];
    };
  }>({
    query: GET_TEACHER_LIST,
    fetchPolicy: "cache-first",
  });

  const payload = result.data;

  if (!payload) {
    // handle invalid teacher slug
    return null;
  }

  return payload.teachers.edges.map((teacher) => ({
    id: teacher.node.id,
    slug: teacher.node.slug,
    name: teacher.node.teacherFieldGroup.name,
    profileSrc: teacher.node.teacherFieldGroup.profilePicture.sourceUrl,
    title: teacher.node.teacherFieldGroup.title,
    description: teacher.node.teacherFieldGroup.description,
    isReady: teacher.node.teacherFieldGroup.ready,
  }));
};

export default getTeacherBySlugLoader;
