import { Box, Grid } from "@mui/material";
import ContentWrapper from "component/ContentWrapper";
import Footer from "component/Footer";
import DesktopNavigation from "component/Navigation/DesktopNavigation";
import { useBreakpoint } from "hooks/useBreakpoint";
import MobileNavigation from "component/Navigation/MobileNavigation";
import { Outlet } from "react-router-dom";

function ResponsiveNavigation() {
  const { isDesktop } = useBreakpoint();

  return (
    <>
      {isDesktop && (
        <Grid item lg={2} xl={2}>
          <DesktopNavigation position="sticky" top={0} py={6} />
        </Grid>
      )}
      {!isDesktop && <MobileNavigation />}
    </>
  );
}

export default function PageWrapper({ children }: React.PropsWithChildren) {
  return (
    <>
      <Box bgcolor="#FFEFEF" width="100%">
        <ContentWrapper>
          <Grid container spacing={2}>
            <ResponsiveNavigation />
            <Grid
              item
              xs={12}
              lg={10}
              xl={10}
              my={[1, 1, 1, 4]}
              px={[0, 0, 0, 3]}
            >
              <Outlet />
            </Grid>
          </Grid>
        </ContentWrapper>
      </Box>
      <Footer />
    </>
  );
}
