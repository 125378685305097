import { Grid, Typography, css } from "@mui/material";
import { Link } from "react-router-dom";
import { News } from "type/type";
/** @jsxImportSource @emotion/react */

function NewsRow({ news: { date, title, url } }: { news: News }) {
  return (
    <Grid
      py={2}
      direction="row"
      spacing={1}
      borderBottom="0.05rem solid #707070"
      container
    >
      <Grid item xs={4} lg={2}>
        <Typography variant="M" color="#707070">
          {date}
        </Typography>
      </Grid>
      <Grid item xs={8} lg={10}>
        <Link
          to={url}
          target="_blank"
          rel="noreferrer"
          css={css`
            text-decoration: none;
            color: rgba(0, 0, 0, 0.87);
          `}
        >
          <Typography variant="M" py={4}>
            {title}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default NewsRow;
