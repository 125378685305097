import { AppBar, Drawer, Toolbar, IconButton, css, Box } from "@mui/material";
import UniversityIcon from "component/Icons/UniversityIcon";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationContent from "component/Navigation/NavigationContent";
import { Link } from "react-router-dom";
/** @jsxImportSource @emotion/react */

const barHeightCss = {
  maxHeight: "100px",
  mixHeight: "50px",
};

export default function MobileNavigation() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          ...barHeightCss,
          bgcolor: "#FFD0D0",
          left: 0,
          width: "100vw",
          height: "64px",
        }}
      >
        <Toolbar sx={{ position: "relative" }}>
          <Link
            to="/"
            css={css`
              width: 100%;
              height: 100%;
            `}
          >
            <UniversityIcon />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              mr: 2,
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              ...barHeightCss,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <NavigationContent height="100%" spacing={3} p={3} />
      </Drawer>
      <Box height={72} width="100%" />
    </>
  );
}
