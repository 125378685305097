import { Box } from "@mui/material";

export default function StandardSection({
  Header,
  children,
}: {
  Header?: React.ReactNode;
} & React.PropsWithChildren) {
  return (
    <Box bgcolor="#FFF" overflow="hidden" borderRadius="2rem" px={[1, 4, 8]}>
      {Header}
      <Box>{children}</Box>
    </Box>
  );
}
