import { Box, BoxProps } from "@mui/material";
import UniversityIcon from "component/Icons/UniversityIcon";
import NavigationContent from "component/Navigation/NavigationContent";
import { Link } from "react-router-dom";

function DesktopNavigation(props: BoxProps) {
  return (
    <Box {...props} maxWidth="410px">
      <Link to="/">
        <UniversityIcon />
      </Link>
      <NavigationContent
        borderRadius="1rem"
        overflow="hidden"
        py={3}
        px={2}
        spacing={3}
      />
    </Box>
  );
}

export default DesktopNavigation;
