import { Box } from "@mui/material";
import SectionTitle from "component/SectionTitle";
import StandardSection from "component/StandardSection";
import getTeacherBySlugLoader from "graphql/loaders/getTeacherBySlugLoader";
import { useLoaderData } from "react-router";

export default function TeacherPage() {
  const teacherData = useLoaderData() as Awaited<
    ReturnType<typeof getTeacherBySlugLoader>
  >;

  if (!teacherData) return <></>;

  return (
    <StandardSection
      Header={<SectionTitle title={teacherData.title} titleVariant="XL" />}
    >
      <Box dangerouslySetInnerHTML={{ __html: teacherData.content }} />
    </StandardSection>
  );
}
