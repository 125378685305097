import { useEffect, useState } from "react";
import { VideoData } from "type/type";
import { constructYoutubeUrl } from "utils/utils";

const YOUTUBE_PLAYLIST = "PLDkgPfLvhPYwbUVSUrZBUTRHCCkKPxAdn";
const YOUTUBE_SHORT_PLAYLIST = "PLDkgPfLvhPYx5KKXBg73foa2HkJ9Z56cC";

function useVideoDataList({
  type = "youtube",
}: {
  type?: "short" | "youtube";
} = {}) {
  const [videoData, setVideoData] = useState<VideoData[] | undefined>();

  useEffect(() => {
    async function fetchVideoList() {
      // fetchVideoData
      try {
        const playlistId =
          type === "youtube" ? YOUTUBE_PLAYLIST : YOUTUBE_SHORT_PLAYLIST;

        const response = await fetch(
          `/wp-json/tsu/v1/video/?playlistId=${playlistId}`
        );
        const data = await response.json();

        const videoData = data.items.flatMap((item: any) => {
          if (Object.keys(item.snippet.thumbnails).length !== 0) {
            return {
              url: constructYoutubeUrl(item.snippet.resourceId.videoId),
              title: item.snippet.title,
            };
          }

          return [];
        });
        setVideoData(videoData);
      } catch (e) {
        console.error(e);
      }
    }
    fetchVideoList();
  }, [type]);

  return videoData;
}

export default useVideoDataList;
