import { News } from "type/type";

const getLatestNewsLoader = async (): Promise<News[]> => {
  try {
    const response: any = await fetch(`/wp-json/tsu/v1/news/`);
    const newsData = (await response.json()) as News[];

    return newsData.slice(0, 5);
  } catch (e) {
    return [];
  }
};

export default getLatestNewsLoader;
