/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import infoImage1 from "assets/images/campus-life/1.jpg";
import infoImage2 from "assets/images/campus-life/2.jpg";
import infoImage3 from "assets/images/campus-life/3.jpg";
import SectionTitle from "component/SectionTitle";

function CampusLifeInfo() {
  return (
    <Box id="feature-video">
      <SectionTitle title="キャンパスライフ" textAlign="center" />
      <Box display="flex" justifyContent="center" flexDirection="column">
        <img src={infoImage1} alt="年間スケジュール" />
        <img src={infoImage2} alt="時間割例（１年生前期）" />
        <img src={infoImage3} alt="時間割例（２年生前期）" />
      </Box>
    </Box>
  );
}

export default CampusLifeInfo;
