import PageWrapper from "routes/PageWrapper";
import HomePage from "routes/HomePage";
import { Navigate, createBrowserRouter } from "react-router-dom";
import getLatestNewsLoader from "graphql/loaders/getLatestNewsLoader";
import ErrorBoundary from "routes/ErrorBoundary";
import getPostBySlugLoader from "graphql/loaders/getNewsBySlugLoader";
import NewsPage from "routes/NewsPage";
import CharacteristicPage from "routes/CharacteristicPage";
import getCharacteristicVideoLoader from "routes/loader/getCharacteristicVideoLoader";
import CampusLifePage from "routes/CampusLifePage";
import TeacherPage from "routes/TeacherPage";
import getTeacherBySlugLoader from "graphql/loaders/getTeacherBySlugLoader";
import AccessPage from "routes/AccessPage";
import getTeachersLoader from "graphql/loaders/getTeachersLoader";
import TeacherListPage from "routes/TeacherListPage";
import AlumniPage from "routes/AlumniPage";
import getYoutubeVideoDataLoader from "routes/loader/getYoutubeVideoDataLoader";
import getCharacteristicBySlugLoader from "graphql/loaders/getCharacteristicBySlugLoader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageWrapper />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: getLatestNewsLoader,
      },
      {
        path: "/news/:slug",
        element: <NewsPage />,
        loader: getPostBySlugLoader as (params: any) => Promise<any>,
      },
      {
        path: "/characteristic/:id",
        element: <CharacteristicPage />,
        //@ts-expect-error
        loader: async (routeObject: { params: { id: string } }) => {
          const videoData = getCharacteristicVideoLoader(routeObject.params.id);
          const postData = getCharacteristicBySlugLoader(routeObject.params.id);

          return {
            videoData: await videoData,
            postData: await postData,
          };
        },
      },
      {
        path: "/teacher/:slug",
        element: <TeacherPage />,
        loader: getTeacherBySlugLoader as (params: any) => Promise<any>,
      },
      {
        path: "/campus-life",
        element: <CampusLifePage />,
      },
      {
        path: "/access",
        element: <AccessPage />,
      },
      {
        path: "/teacher",
        element: <TeacherListPage />,
        loader: getTeachersLoader as (params: any) => Promise<any>,
      },
      {
        path: "/alumni",
        element: <AlumniPage />,
        loader: () =>
          getYoutubeVideoDataLoader("PLDkgPfLvhPYwuoggKAI5BJ2zTh_V1t1b2"),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />,
  },
]);

export default router;
