import { CharacteristicData, VideoData } from "type/type";
import { constructYoutubeUrl } from "utils/utils";

const DATA = [
  {
    playlist: "PLDkgPfLvhPYw-fFM3nnU5_KHk0w1EcFvl",
    subTitle: "都心で駅チカ・綺麗なキャンパス",
  },
  {
    playlist: "PLDkgPfLvhPYwXpSnnM-XJFBSpvOJKJ9Q3",
    subTitle: "最短距離で夢を掴む！",
  },
  {
    playlist: "PLDkgPfLvhPYxlFbEO1dj5ufkEQLHWAPbN",
    subTitle: "素敵な先生、友達たくさん",
  },
  {
    playlist: "PLDkgPfLvhPYwFZuYzYOFns_9SarirMGvy",
    subTitle: "ぎゅっと詰まった 2 年間",
  },
] as const;

const shortVideoRegex = /#shorts$/;

const getCharacteristicVideoLoader = async (
  id: string
): Promise<CharacteristicData | null> => {
  const data = DATA[parseInt(id) - 1];

  try {
    const response = await fetch(
      `${process.env.REACT_APP_TSU_API_ENDPOINT}/video/?playlistId=${data.playlist}`
    );
    const playListData = await response.json();

    const short: VideoData[] = [];
    const youtube: VideoData[] = [];

    playListData.items.reverse();

    playListData.items.forEach((item: any) => {
      if (Object.keys(item.snippet.thumbnails).length !== 0) {
        const title = item.snippet.title;

        const videoData: VideoData = {
          title,
          url: constructYoutubeUrl(item.snippet.resourceId.videoId),
        };

        if (shortVideoRegex.test(title)) {
          short.push(videoData);
        } else {
          youtube.push(videoData);
        }
      }
    });

    return {
      short,
      youtube,
      subTitle: data.subTitle,
    };
  } catch (e) {
    return null;
  }
};

export default getCharacteristicVideoLoader;
