import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoint = () => {
  const theme = useTheme();
  let breakpoint = 0;
  breakpoint = useMediaQuery(theme.breakpoints.up("sm")) ? 1 : breakpoint;
  breakpoint = useMediaQuery(theme.breakpoints.up("md")) ? 2 : breakpoint;
  breakpoint = useMediaQuery(theme.breakpoints.up("lg")) ? 3 : breakpoint;
  breakpoint = useMediaQuery(theme.breakpoints.up("xl")) ? 4 : breakpoint;

  return {
    isDesktop: breakpoint > 2,
    breakpoint,
  };
};

export { useBreakpoint };
