/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import { Navigate, useLoaderData } from "react-router";
import getYoutubeVideoDataLoader from "routes/loader/getYoutubeVideoDataLoader";
import AlumniVideoSection from "component/AlumniContent/AlumniVideoSection";
import useScrollToTop from "hooks/useScrollToTop";

export default function AlumniPage() {
  const videoData = useLoaderData() as Awaited<
    ReturnType<typeof getYoutubeVideoDataLoader>
  >;
  useScrollToTop();

  if (videoData === null) {
    return <Navigate replace to="/" />;
  }

  return (
    <Box marginTop={[9, 9, 9, 2]}>
      <AlumniVideoSection alumniPageData={videoData} />
    </Box>
  );
}
