import SectionTitle from "component/SectionTitle";
import StandardSection from "component/StandardSection";
import getNewsBySlugLoader from "graphql/loaders/getNewsBySlugLoader";
import { useLoaderData } from "react-router";

export default function NewsPage() {
  const news = useLoaderData() as Awaited<
    ReturnType<typeof getNewsBySlugLoader>
  >;

  if (!news) return <></>;

  return (
    <StandardSection Header={<SectionTitle title={news.title} />}>
      <div dangerouslySetInnerHTML={{ __html: news.content }} />
    </StandardSection>
  );
}
