import { TeacherData } from "type/type";
import { gql } from "@apollo/client";
import apolloClient from "graphql/client";

const GET_TEACHER_BY_SLUG = gql`
  query getTeacherBySlug($slug: String!) {
    teacherBy(slug: $slug) {
      content(format: RENDERED)
      title(format: RENDERED)
    }
  }
`;

const getTeacherBySlugLoader = async ({
  params: { slug },
}: {
  params: {
    slug: string;
  };
}): Promise<TeacherData | null> => {
  const result: any = await apolloClient.query({
    query: GET_TEACHER_BY_SLUG,
    variables: {
      slug: encodeURIComponent(slug).toLowerCase(),
    },
    fetchPolicy: "cache-first",
  });

  const payload = result.data?.teacherBy;

  if (!payload) {
    // handle invalid teacher slug
    return null;
  }

  return {
    content: payload.content,
    title: payload.title,
  };
};

export default getTeacherBySlugLoader;
