import React, { useState, useEffect } from "react";
import { Box, Fab, css } from "@mui/material";
import openCampusIcon from "assets/images/open-campus-icon.png";
import { useBreakpoint } from "hooks/useBreakpoint";
/** @jsxImportSource @emotion/react */

const FloatingButton: React.FC = () => {
  const [hideButton, setHideButton] = useState<boolean>(false);
  const { breakpoint } = useBreakpoint();
  const imageSize = breakpoint > 2 ? (breakpoint > 3 ? 200 : 150) : 100;

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footer");
      if (footer && footer.getBoundingClientRect().top <= window.innerHeight) {
        setHideButton(true);
      } else {
        setHideButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {!hideButton && (
        <Box
          sx={{
            position: "fixed",
            bottom: ["25px", "37px", "50px"],
            right: ["25px", "37px", "50px"],
            // boxShadow:
            //   "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
            // transform:
            //   "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
          zIndex="1000"
          onClick={() => {
            const footer = document.getElementById("open-campus");
            footer?.scrollIntoView();
          }}
        >
          <img
            src={openCampusIcon}
            alt="オープンキャンパス"
            width={`${imageSize}px`}
            css={css`
              border-radius: 50%;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
                rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
              position: relative;
              cursor: pointer;
              &:hover {
                top: 2px;
                left: 2px;
              }
            `}
          />
        </Box>
      )}
    </>
  );
};

export default FloatingButton;
