/* eslint-disable jsx-a11y/alt-text */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Button, Grid, Typography } from "@mui/material";
import SectionTitle from "component/SectionTitle";
import topImage from "assets/images/access/jujocampus-img.webp";
import accessSmallScaleImg1 from "assets/images/access/jujo-accessmap-01.jpg";
import accessSmallScaleImg2 from "assets/images/access/jujo-access02-1.webp";
import accessLargeScaleImg1 from "assets/images/access/jujo_access_2022.12.gif";
import accessLargeScaleImg2 from "assets/images/access/higashijujo_access_2022.12.gif";

export default function AccessInfo() {
  return (
    <Box>
      <SectionTitle title="キャンパスアクセス" textAlign="center" />
      <Box display="flex" justifyContent="center" flexDirection="column" mb={2}>
        <img src={topImage} />

        <Grid container my={[1, 1, 2]} spacing={[1, 1, 2]}>
          <Grid item xs={12} lg={6}>
            <img
              src={accessSmallScaleImg1}
              css={css`
                width: 100%;
              `}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <img
              src={accessSmallScaleImg2}
              css={css`
                width: 100%;
              `}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="S">十条駅～大学</Typography>
            <img
              src={accessLargeScaleImg1}
              css={css`
                width: 100%;
              `}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="S">東十条駅～大学</Typography>
            <img
              src={accessLargeScaleImg2}
              css={css`
                width: 100%;
              `}
            />
          </Grid>
        </Grid>

        <Typography variant="L">所在地</Typography>
        <Grid container my={[1, 1, 2]} spacing={[1, 1, 2]}>
          <Grid item xs={12} lg={6}>
            <Typography>〒114-0033　東京都北区十条台1-7-13</Typography>
            <Typography>
              ●JR埼京線「十条駅」南口下車徒歩5分／JR京浜東北線「東十条駅」南口下車徒歩10分
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6} display="flex">
            <Button
              size="small"
              href="https://goo.gl/maps/1jEovmwf9CZPuoo66"
              target="_blank"
              rel="noreferrer"
              sx={{
                bgcolor: "#EDA14B",
                color: "#FFF",
                "&: hover, &:active": {
                  bgcolor: "#ba8a54",
                },
                marginX: "auto",
                marginY: "0.5rem",
              }}
            >
              <Typography
                variant="M"
                color="#FFF"
                px={4}
                css={css`
                  &:hover {
                    color: #804040;
                  }
                `}
              >
                Googleマップはこちら
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
