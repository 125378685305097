import { VideoPageData, VideoData } from "type/type";
import { constructYoutubeUrl } from "utils/utils";

const shortVideoRegex = /#shorts$/;

const getYoutubeVideoDataLoader = async (
  playlistId: string
): Promise<VideoPageData | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_TSU_API_ENDPOINT}/video/?playlistId=${playlistId}`
    );
    const playListData = await response.json();

    const short: VideoData[] = [];
    const youtube: VideoData[] = [];

    playListData.items.reverse();

    playListData.items.forEach((item: any) => {
      if (Object.keys(item.snippet.thumbnails).length !== 0) {
        const title = item.snippet.title;

        const videoData: VideoData = {
          title,
          url: constructYoutubeUrl(item.snippet.resourceId.videoId),
        };

        if (shortVideoRegex.test(title)) {
          short.push(videoData);
        } else {
          youtube.push(videoData);
        }
      }
    });

    return {
      short,
      youtube,
    };
  } catch (e) {
    return null;
  }
};

export default getYoutubeVideoDataLoader;
