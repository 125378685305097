/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import FeatureCard from "component/HomeContent/FeatureCardNew";
import feature1Img from "assets/images/feature/1.jpg";
import feature2Img from "assets/images/feature/2.jpg";
import feature3Img from "assets/images/feature/3.jpg";
import feature4Img from "assets/images/feature/4.jpg";
import SectionTitle from "component/SectionTitle";
import { ReactComponent as FeatureSubtitle } from "assets/text/feature-title.svg";

function FeatureSection() {
  return (
    <Box py={6} position="relative">
      <SectionTitle
        title="FEATURE"
        subTitle="幼児教育科のここが魅力！"
        textAlign={["center", "center", "center", "left"]}
      />
      <Box py={4}>
        <Box width="80%" margin="auto">
          <FeatureSubtitle
            css={css`
              width: 100%;
              display: block;
              margin: auto;
            `}
          />
        </Box>
        <Typography align="center" variant="M" color="#000">
          東京成徳短期大学幼児教育科の魅力ポイントをご紹介いたします。
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <FeatureCard
          imageSrc={feature1Img}
          title="都心で駅チカ・綺麗なキャンパス"
          content="池袋から５分の十条駅が最寄り駅！駅から徒歩５分！\nコンサートができる大ホールやカフェテリアなど、綺麗な施設！"
          buttons={[
            {
              cta: "キャンパスマップ",
              href: "https://www.tsu.ac.jp/campuslife/campus-jujo/",
            },
          ]}
          url="/characteristic/1"
        />
        <FeatureCard
          imageSrc={feature2Img}
          title="最短距離で夢を掴む！"
          content="保育を目指すために必要な資格！\n就職に向かって"
          buttons={[
            {
              cta: "短期大学の就職率",
              href: "https://www.tsu.ac.jp/career/data/",
            },
          ]}
          url="/characteristic/2"
        />
        <FeatureCard
          imageSrc={feature3Img}
          title="素敵な先生、友達たくさん"
          content="クラス担任制！\n充実した学びや行事！"
          buttons={[
            {
              cta: "短期大学学びのサポート",
              href: "https://www.tsu.ac.jp/junior-college/support/",
            },
          ]}
          url="/characteristic/3"
        />
        <FeatureCard
          imageSrc={feature4Img}
          title="ぎゅっと詰まった 2 年間"
          content="実践力を育む授業！\n実践力が身につく実習！"
          buttons={[
            {
              cta: "短期大学授業紹介",
              href: "https://www.tsu.ac.jp/junior-college/class/",
            },
            {
              cta: "実習",
              href: "https://www.tsu.ac.jp/junior-college/practice/",
            },
          ]}
          url="/characteristic/4"
        />
      </Grid>
    </Box>
  );
}

export default FeatureSection;
