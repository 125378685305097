/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box } from "@mui/material";
import YoutubeBlock from "component/YoutubeBlockNew";
import YoutubeShortBlock from "component/YoutubeShortBlock";
import useVideoData from "hooks/useVideoData";
import { ReactComponent as VideoTitle } from "assets/text/video-title.svg";
import HorizontalScrollContainer from "component/HorizontalScrollContainer";
import { getResponsiveSize } from "utils/responsiveUtils";
import { useBreakpoint } from "hooks/useBreakpoint";

function VideoSection() {
  const { breakpoint } = useBreakpoint();
  const youtubeVideoData = useVideoData({
    type: "youtube",
  });
  const shortVideoData = useVideoData({
    type: "short",
  });

  if (
    (!shortVideoData && !shortVideoData) ||
    (youtubeVideoData?.length === 0 && shortVideoData.length === 0)
  ) {
    return null;
  }

  return (
    <Box>
      <Box width="80%" margin="auto">
        <VideoTitle
          title="動画で見る"
          css={css`
            width: 100%;
            display: block;
            margin: auto;
          `}
        />
      </Box>

      <Box marginBottom={4}>
        <HorizontalScrollContainer
          buttonProps={{ top: "45%" }}
          columnGap={getResponsiveSize("2rem", breakpoint)}
        >
          {shortVideoData?.map((data, idx) => (
            <YoutubeShortBlock url={data.url} title={data.title} key={idx} />
          ))}
        </HorizontalScrollContainer>
      </Box>
      <Box>
        <HorizontalScrollContainer buttonProps={{ top: "35%" }}>
          {youtubeVideoData?.map((data, idx) => (
            <YoutubeBlock url={data.url} title={data.title} key={idx} />
          ))}
        </HorizontalScrollContainer>
      </Box>
    </Box>
  );
}

export default VideoSection;
