import ReactPlayer from "react-player";
import video from "assets/video/pc.mp4";
import { Box } from "@mui/material";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export default function TopMobileVideoSection() {
  return (
    <Box
      height="100vh"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginTop={2}
    >
      <ReactPlayer
        playing
        controls={false}
        muted
        loop
        playsinline
        url={video}
        height="80vh"
        width="100vw"
        css={css`
          video {
            object-fit: cover;
            object-position: 50% 50%;
          }
        `}
      />
    </Box>
  );
}
