import { Box, Chip, Link } from "@mui/material";
import NewsRow from "component/HomeContent/NewsRow";
import SectionTitle from "component/SectionTitle";
import { ReactComponent as NewsRightArrow } from "assets/icons/newsRightArrow.svg";
import { getResponsiveSize } from "utils/responsiveUtils";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useLoaderData } from "react-router";
import getLatestNewsLoader from "graphql/loaders/getLatestNewsLoader";
import StandardSection from "component/StandardSection";

function NewsSection() {
  const { breakpoint } = useBreakpoint();
  const newsList = useLoaderData() as Awaited<
    ReturnType<typeof getLatestNewsLoader>
  >;

  if (!newsList || newsList.length === 0) return null;

  return (
    <StandardSection
      Header={
        <SectionTitle title="NEWS" subTitle="新着情報" textAlign="center" />
      }
    >
      <Box>
        {newsList?.map((news, idx) => (
          <NewsRow key={idx} news={news} />
        ))}
      </Box>
      <Box py={6} display="flex" justifyContent="center">
        <Link
          href="https://www.tsu.ac.jp/junior-college-news/"
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          <Chip
            sx={{
              bgcolor: "#EDA14B",
              color: "#FFF",
              width: `${getResponsiveSize("13rem", breakpoint)}`,
              fontSize: `${getResponsiveSize("1.25rem", breakpoint)}`,
              "&: hover, &:active": {
                bgcolor: "#ba8a54",
              },
            }}
            label="もっと見る"
            onClick={() => {}}
            onDelete={() => {}}
            deleteIcon={<NewsRightArrow />}
          />
        </Link>
      </Box>
    </StandardSection>
  );
}

export default NewsSection;
