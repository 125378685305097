import { ReactComponent as NewsIcon } from "assets/linkIcons/news.svg";
import { ReactComponent as OpenCampusIcon } from "assets/linkIcons/opencampus.svg";
import { ReactComponent as StudyIcon } from "assets/linkIcons/study.svg";
import { ReactComponent as AdmissionIcon } from "assets/linkIcons/admission.svg";
import { ReactComponent as AccessIcon } from "assets/linkIcons/access.svg";
import { ReactComponent as InfoIcon } from "assets/linkIcons/info.svg";
import { ReactComponent as StaffIcon } from "assets/linkIcons/staff.svg";
import { ReactComponent as GraduateIcon } from "assets/linkIcons/graduate.svg";
import { ReactComponent as MailIcon } from "assets/linkIcons/mail.svg";

export const navigationList: readonly {
  title: string;
  url: string;
  icon: React.FunctionComponent;
  internal?: boolean;
}[] = [
  {
    title: "新着情報",
    url: "https://www.tsu.ac.jp/junior-college-news/",
    icon: NewsIcon,
  },
  {
    title: "幼児教育科の概要",
    url: "https://www.tsu.ac.jp/junior-college/#667e36ec",
    icon: StudyIcon,
  },
  {
    title: "オープンキャンパス",
    url: "https://www.tsu.ac.jp/event/opencampus/",
    icon: OpenCampusIcon,
  },
  {
    title: "キャンパスライフ",
    url: "/campus-life",
    icon: OpenCampusIcon,
    internal: true,
  },
  {
    title: "教員・研究室紹介",
    url: "/teacher",
    icon: StaffIcon,
    internal: true,
  },
  {
    title: "入試情報",
    url: "https://www.tsu.ac.jp/juken/junior-college/",
    icon: AdmissionIcon,
  },
  {
    title: "資料請求",
    url: "https://www.tsu.ac.jp/juken/request/",
    icon: InfoIcon,
  },
  {
    title: "交通アクセス",
    url: "/access",
    icon: AccessIcon,
    internal: true,
  },
  {
    title: "幼児教育科の卒業生",
    url: "/alumni",
    icon: GraduateIcon,
    internal: true,
  },
  // {
  //   title: "お問い合わせ",
  //   url: "/",
  //   icon: MailIcon,
  // },
] as const;
