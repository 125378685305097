import { Box } from "@mui/material";
import AccessInfo from "component/AccessContent/AccessInfo";

export default function AccessPage() {
  return (
    <Box marginTop={[9, 9, 9, 2]}>
      <AccessInfo />
    </Box>
  );
}
