/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, BoxProps, Stack, StackProps } from "@mui/material";
import useResizeObserver from "use-resize-observer";
import { ReactComponent as ScrollIcon } from "assets/icons/scrollIcon.svg";
import { PropsWithChildren, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { getResponsiveSize } from "utils/responsiveUtils";
import { useBreakpoint } from "hooks/useBreakpoint";

function ScrollButton(props: BoxProps) {
  return (
    <Box {...props} display="inline-block">
      <ScrollIcon title="スクロールボタン" />
    </Box>
  );
}

const SCROLL_RATE = 5;
const SCROLL_END_PADDING = 25;

function HorizontalScrollContainer({
  buttonProps,
  children,
  columnGap,
  ...rest
}: PropsWithChildren & {
  buttonProps?: BoxProps;
  columnGap?: string | number;
}) {
  const { breakpoint, isDesktop } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  useResizeObserver<HTMLDivElement>({
    ref: containerRef,
  });

  const [end, setEnd] = useState<"left" | "right" | null>("left");

  const showScrollButton =
    (containerRef.current?.scrollWidth || 0) >
      (containerRef.current?.clientWidth || 0) && Array.isArray(children);

  const intervalRef = useRef<number | null>(null);
  const startScrolling = (direction: "left" | "right") => {
    if (intervalRef.current) return;

    const scrollRate = direction === "right" ? SCROLL_RATE : SCROLL_RATE * -1;

    intervalRef.current = setInterval(() => {
      if (!containerRef.current) return;
      containerRef.current.scrollLeft += scrollRate;
    }, 10) as unknown as number;
  };

  const stopScrolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <Box
      css={css`
        position: relative;
      `}
    >
      <ScrollContainer
        innerRef={containerRef}
        vertical={false}
        horizontal={true}
        hideScrollbars={isDesktop ? false : true}
        onScroll={() => {
          if (!containerRef.current) return;
          if (containerRef.current.scrollLeft === 0) {
            setEnd("left");
            stopScrolling();
            return;
          }

          if (
            containerRef.current.scrollLeft +
              containerRef.current.clientWidth +
              SCROLL_END_PADDING >=
            containerRef.current.scrollWidth
          ) {
            setEnd("right");
            stopScrolling();
            return;
          }

          setEnd(null);
        }}
        css={css`
          display: flex;
          column-gap: ${columnGap || getResponsiveSize("1rem", breakpoint)};
          padding-right: ${SCROLL_END_PADDING}px;
        `}
      >
        {children}
      </ScrollContainer>
      {showScrollButton && end !== "left" && (
        <ScrollButton
          position="absolute"
          top="66%"
          left={-25}
          sx={{
            transform: "scaleX(-1)",
          }}
          onMouseDown={() => startScrolling("left")}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling("left")}
          onTouchEnd={stopScrolling}
          onTouchCancel={stopScrolling}
          {...buttonProps}
        />
      )}
      {showScrollButton && end !== "right" && (
        <ScrollButton
          position="absolute"
          top="66%"
          right={-25}
          onMouseDown={() => startScrolling("right")}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling("right")}
          onTouchEnd={stopScrolling}
          onTouchCancel={stopScrolling}
          {...buttonProps}
        />
      )}
    </Box>
  );
}

export default HorizontalScrollContainer;
