import { Box, BoxProps, Typography } from "@mui/material";
import { ComponentProps } from "react";

function SectionTitle({
  title,
  subTitle,
  titleVariant = "Title",
  ...rest
}: {
  title: string;
  subTitle?: string;
  titleVariant?: ComponentProps<typeof Typography>["variant"];
} & BoxProps) {
  return (
    <Box {...rest}>
      <p>
        <Typography variant={titleVariant} color="#000" pt={4}>
          {title}
        </Typography>
      </p>
      {subTitle && (
        <p>
          <Typography variant="M" color="#4579CE" py={2}>
            {subTitle}
          </Typography>
        </p>
      )}
    </Box>
  );
}
export default SectionTitle;
