import { Stack, StackProps } from "@mui/material";
import NavigationLink from "component/Navigation/NavigationLink";
import SocialSvgLink from "component/Navigation/SocialSvgLink";
import { navigationList } from "component/Navigation/navigationList";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/youtube.svg";

export default function NavigationContent(props: StackProps) {
  return (
    <Stack bgcolor="#FF8080" {...props}>
      {navigationList.map((linkInfo) => (
        <NavigationLink
          key={linkInfo.title}
          Icon={linkInfo.icon}
          title={linkInfo.title}
          url={linkInfo.url}
          internal={linkInfo.internal}
        />
      ))}
      <Stack direction="row" justifyContent="space-evenly">
        {/* <SocialSvgLink
          height="48px"
          Icon={TwitterIcon}
          title="twitter"
          url="/"
        /> */}
        <SocialSvgLink
          height="48px"
          Icon={InstagramIcon}
          title="instagram"
          url="https://www.instagram.com/tokyo_seitoku_college/"
        />
        <SocialSvgLink
          height="48px"
          Icon={YoutubeIcon}
          title="youtube"
          url="https://www.youtube.com/@tokyo-seitoku-tan"
        />
      </Stack>
    </Stack>
  );
}
