/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Card, CardActionArea, Link, Typography } from "@mui/material";
import { ReactComponent as OpenCampusArrow } from "assets/icons/open-campus-arrow.svg";

function OpenCampusCard2({
  imgSrc,
  date,
  alt,
  href,
  eventType = "オープンキャンパス",
}: {
  imgSrc: string;
  date: string;
  alt: string;
  href: string;
  eventType?: string;
}) {
  return (
    <Box minWidth={323} maxWidth={323} height={260} position="relative">
      <img
        css={css`
          width: 100%;
          object-fit: contain;
          border-radius: 40px;
        `}
        src={imgSrc}
        alt={alt}
      />
      <Card
        sx={{
          textAlign: "center",
          borderRadius: "40px",
          width: "292px",
          height: "108px",
          top: "50%",
          left: "5%",
          position: "absolute",
        }}
      >
        <CardActionArea
          sx={{
            height: "100%",
          }}
          component={Link}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            height="100%"
          >
            <Typography pt={[2, 2, 0, 0]} variant="L" color="#49555B">
              {date}
            </Typography>
            <Typography variant="S" color="#49555B">
              {eventType}
            </Typography>
            <OpenCampusArrow
              css={css`
                display: block;
                margin: auto;
              `}
            />
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default OpenCampusCard2;
