import { Link } from "@mui/material";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export default function SocialSvgLink({
  Icon,
  title,
  url,
  height,
}: {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  title: string;
  url: string;
  height?: string;
}) {
  return (
    <Link target="_blank" rel="noreferrer" href={url} underline="none">
      <Icon
        css={
          height &&
          css`
            height: ${height};
          `
        }
        title={title}
      />
    </Link>
  );
}
