import { NewsData } from "type/type";
import { gql } from "@apollo/client";
import apolloClient from "graphql/client";

const GET_POST_BY_SLUG = gql`
  query getPostBySlug($slug: String!) {
    postBy(slug: $slug) {
      id
      content(format: RENDERED)
      date
      modified
      title(format: RENDERED)
      slug
    }
  }
`;

const getPostBySlugLoader = async ({
  params: { slug },
}: {
  params: {
    slug: string;
  };
}): Promise<NewsData | null> => {
  const result: any = await apolloClient.query({
    query: GET_POST_BY_SLUG,
    variables: {
      slug,
    },
    fetchPolicy: "cache-first",
  });

  const payload = result.data?.postBy;

  if (!payload) {
    // handle invalid post slug
    return null;
  }

  return {
    content: payload.content,
    date: payload.date,
    modified: payload.modified,
    slug: payload.slug,
    title: payload.title,
  };
};

export default getPostBySlugLoader;
