import { Box, Grid, Link, Typography } from "@mui/material";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function NavigationLink({
  Icon,
  title,
  url,
  internal,
}: {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  title: string;
  url: string;
  internal?: boolean;
}) {
  return (
    <Box>
      <Link
        target={internal ? undefined : "_blank"}
        rel="noreferrer"
        variant="S"
        color="#FFF"
        href={url}
        underline="none"
      >
        <Grid container spacing={3} direction="row">
          <Grid
            item
            xs={3}
            lg={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Icon
              title={title}
              css={css`
                width: 30px;
              `}
            />
          </Grid>
          <Grid item xs={9} lg={10}>
            <Typography
              variant="XS"
              css={css`
                &:hover {
                  color: #804040;
                }
              `}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
}

export default NavigationLink;
