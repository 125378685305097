/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import YoutubeBlock from "component/YoutubeBlockNew";
import YoutubeShortBlock from "component/YoutubeShortBlock";
import HorizontalScrollContainer from "component/HorizontalScrollContainer";
import { getResponsiveSize } from "utils/responsiveUtils";
import { useBreakpoint } from "hooks/useBreakpoint";
import SectionTitle from "component/SectionTitle";
import { AlumniPageData } from "type/type";

function AlumniVideoSection({
  alumniPageData,
}: {
  alumniPageData: AlumniPageData;
}) {
  const { breakpoint } = useBreakpoint();

  return (
    <Box>
      <SectionTitle
        title="幼児教育科の卒業生"
        textAlign="center"
        subTitle="成長した姿・在学生との交流"
      />
      <Box marginBottom={4}>
        <HorizontalScrollContainer
          buttonProps={{ top: "45%" }}
          columnGap={getResponsiveSize("2rem", breakpoint)}
        >
          {alumniPageData.short.map((data, idx) => (
            <YoutubeShortBlock url={data.url} title={data.title} key={idx} />
          ))}
        </HorizontalScrollContainer>
      </Box>
      <Box>
        <HorizontalScrollContainer buttonProps={{ top: "35%" }}>
          {alumniPageData.youtube.map((data, idx) => (
            <YoutubeBlock url={data.url} title={data.title} key={idx} />
          ))}
        </HorizontalScrollContainer>
      </Box>
    </Box>
  );
}

export default AlumniVideoSection;
