/** @jsxImportSource @emotion/react */
import logo from "assets/logo.png";
import { css } from "@emotion/react";

function UniversityIcon({
  width = "100%",
  height = "100%",
  maxWidth,
  margin,
  minHeight,
}: {
  width?: string;
  height?: string;
  margin?: string;
  maxWidth?: string;
  minHeight?: string;
}) {
  return (
    <img
      css={css`
        ${width &&
        `
         width: ${width};
        `}
        height: ${height};
        object-fit: contain;
        ${maxWidth &&
        `
        max-width: ${maxWidth};
        `}
        ${margin &&
        `
          margin: ${margin};
        `}
        ${minHeight &&
        `
          min-height: ${minHeight};
        `}
      `}
      src={logo}
      alt="TOKYO SEITOKU UNIVERSITY"
    />
  );
}
export default UniversityIcon;
