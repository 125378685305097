import { Box } from "@mui/material";
import SectionTitle from "component/SectionTitle";
import StandardSection from "component/StandardSection";
import TeacherCell from "component/TeacherListPageContent/TeacherCell";
import getTeachersLoader from "graphql/loaders/getTeachersLoader";
import { useLoaderData } from "react-router";

export default function TeacherListPage() {
  const teachers = useLoaderData() as Awaited<
    ReturnType<typeof getTeachersLoader>
  >;

  if (!teachers) return <></>;

  return (
    <StandardSection
      Header={<SectionTitle title="教員・研究室紹介" textAlign="center" />}
    >
      <Box display="flex" flexWrap="wrap">
        {teachers.map((teacher) => (
          <TeacherCell {...teacher} />
        ))}
      </Box>
    </StandardSection>
  );
}
