import { Box, Button, CardActions, CardMedia, Grid, css } from "@mui/material";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { PropsWithChildren } from "react";
/** @jsxImportSource @emotion/react */

const CharacteristicLinkWrapper = ({
  children,
  url,
}: PropsWithChildren & { url: string }) => {
  return (
    <Link
      to={url}
      css={css`
        text-decoration: none;
      `}
    >
      {children}
    </Link>
  );
};

function FeatureCard({
  title,
  content,
  imageSrc,
  buttons,
  url,
}: {
  title: string;
  content: string;
  imageSrc: string;
  buttons?: {
    cta: string;
    href: string;
  }[];
  url: string;
}) {
  return (
    <Grid item xs={12} lg={6} display="flex">
      <Card
        sx={{
          width: "100%",
          borderRadius: 6,
          backgroundColor: "#FF8080",
          marginY: 2,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flex: "1 0 0",
        }}
      >
        <CharacteristicLinkWrapper url={url}>
          <CardMedia
            component="img"
            sx={{
              backgroundSize: "contain",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              maxHeight: "288px",
            }}
            image={imageSrc}
            title={title}
            // onClick={() => {
            //   const featureVideo = document.getElementById("feature-video");
            //   featureVideo?.scrollIntoView();
            // }}
          />
        </CharacteristicLinkWrapper>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          flex="1 0 auto"
        >
          <CardContent
            sx={{
              paddingX: 0,
              flex: "1 0 auto",
            }}
          >
            <Box
              paddingX="1rem"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <CharacteristicLinkWrapper url={url}>
                <Box
                  textAlign="left"
                  // onClick={() => {
                  //   const featureVideo =
                  //     document.getElementById("feature-video");
                  //   featureVideo?.scrollIntoView();
                  // }}
                  paddingBottom={2}
                >
                  <Typography
                    variant="L"
                    color="#FFF"
                    css={css`
                      &:hover {
                        color: #804040;
                      }
                    `}
                  >
                    {title}
                  </Typography>
                </Box>
              </CharacteristicLinkWrapper>
              {content.split("\\n").map((splitString, idx) => (
                <Typography variant="S" component="p" color="#FFF" key={idx}>
                  {splitString}
                </Typography>
              ))}
            </Box>
          </CardContent>
          <CardActions
            sx={{
              flex: "0 0 auto",
            }}
          >
            {buttons &&
              buttons.map((buttonProp, idx) => (
                <Button
                  key={idx}
                  size="small"
                  href={buttonProp.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    variant="M"
                    color="#FFF"
                    css={css`
                      &:hover {
                        color: #804040;
                      }
                    `}
                  >
                    {buttonProp.cta}
                  </Typography>
                </Button>
              ))}
          </CardActions>
        </Box>
      </Card>
    </Grid>
  );
}

export default FeatureCard;
