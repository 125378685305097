import ReactPlayer from "react-player";
import video from "assets/video/pc.mp4";
import { Box } from "@mui/material";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as OpenCampusLogo } from "assets/text/open-campus-title.svg";

export default function TopDesktopVideoSection() {
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Box my={[1, 2, 3, 6]} position="relative">
        <OpenCampusLogo
          css={css`
            display: inline-block;
            width: 20%;
            height: auto;
            position: absolute;
            top: -4%;
            right: -4%;
          `}
          title="Open Campus 東京成徳短期大学"
        />
      </Box>
      <ReactPlayer
        playing
        controls={false}
        muted
        loop
        url={video}
        height="80vh"
        width="100%"
        css={css`
          video {
            object-fit: cover;
            object-position: 50% 50%;
          }
        `}
      />
    </Box>
  );
}
