import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player/youtube";

function YoutubeShortBlock({
  url,
  title,
  onReady,
}: {
  url: string;
  title: string;
  onReady?: () => void;
}) {
  return (
    <Box flex={["0 0 70%", "0 0 70%", "0 0 30%"]} height="100%">
      <Box position="relative" paddingTop="177.78%" height="100%">
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          onReady={onReady}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            borderRadius: "2rem",
            overflow: "hidden",
          }}
        />
      </Box>
      <Typography variant="S" color="#5A5A5A">
        {title}
      </Typography>
    </Box>
  );
}

export default YoutubeShortBlock;
